import { mapObject, mapToObject } from "../utils/objects";
import {
  AnalyticsBucketMetricDto,
  AnalyticsBucketOfBucketMetricDto as AnalyticsBucketOfBucketedValuesMetricDto,
  AnalyticsSingleValueDto,
} from "./Analytics.dtoAdditions";
import {
  AnalyticsAppendixType,
  AnalyticsAppendixTypeMap,
  AnalyticsBucket,
  AnalyticsBucketedValue,
  AnalyticsBucketedValuesValue,
  AnalyticsBucketOfBucketedValuesValue,
  AnalyticsBucketOfBucketValues,
  AnalyticsCategory,
  AnalyticsResults,
  AnalyticsResultsApendix,
  AnalyticsResultsApendixEntry,
  AnalyticsSingleValue,
  AnalyticssMetric,
  AnalyticsUnit,
  AnalyticsValueBase,
} from "./Analytics.types";
import {
  AbstractMetric as AbstractMetricDto,
  AnalyticsBucket as AnalyticsBucketDto,
  AnalyticsMetricName as AnalyticsMetricNameDto,
  AnalyticsResults as AnalyticsResultsDto,
  AnalyticsUnit as AnalyticsUnitDto,
  EventCategory as EventCategoryDto,
  RichAppendixEntry as RichAppendixEntryDto,
} from "./client";
import { dtoToThinPerson } from "./Users";

/** string union mutators */

export const dtoToAnalyticsBucket = <B extends AnalyticsBucket>(dto: AnalyticsBucketDto): B => dto as B;
// TODO: This cast sucks.  B.E. thinks this is EventCategory (see: AnalyticsRowEventCategoryLong).
export const dtoToAnalyticsEventCategory = (dto: EventCategoryDto): AnalyticsCategory =>
  dto as unknown as AnalyticsCategory;
export const dtoToAnalyticsUnit = <U extends AnalyticsUnit>(dto: AnalyticsUnitDto): U => dto as U;
export const analyticssMetricToDto = (dto: AnalyticsMetricNameDto): AnalyticsMetricNameDto =>
  dto as AnalyticsMetricNameDto;

/** Supporting mutators */

export const dtoToBucketedValuesValue = <BUCKET extends AnalyticsBucket>(
  dto
): AnalyticsBucketedValuesValue<BUCKET> => ({ ...dto });

export const dtoToBucketOfBucketedValuesValue = <BUCKET extends AnalyticsBucket, SUB_BUCKET extends AnalyticsBucket>(
  dto
): AnalyticsBucketOfBucketedValuesValue<BUCKET, SUB_BUCKET> => ({ ...dto });

/** AnalyticsValue mutators */

export const dtoToAnalyticsValueBase = <UNIT extends AnalyticsUnit>(
  metricName: AnalyticsMetricNameDto,
  dto: AbstractMetricDto
): AnalyticsValueBase<UNIT> => ({
  metric: metricName,
  unit: dtoToAnalyticsUnit<UNIT>(dto.unit),
  description: dto.description,
});

export const dtoToAnalyticsSingleValue = <UNIT extends AnalyticsUnit>(
  metricName: AnalyticsMetricNameDto,
  dto: AnalyticsSingleValueDto
): AnalyticsSingleValue<UNIT> => ({
  ...dtoToAnalyticsValueBase<UNIT>(metricName, dto),
  value: dto.value,
});

export const dtoToAnalyticsBucketedValue = <UNIT extends AnalyticsUnit, BUCKET extends AnalyticsBucket>(
  metricName: AnalyticsMetricNameDto,
  dto: AnalyticsBucketMetricDto
): AnalyticsBucketedValue<UNIT, BUCKET> => ({
  ...dtoToAnalyticsValueBase<UNIT>(metricName, dto),
  values: dto.values.map((value) => dtoToBucketedValuesValue(value)),
  bucket: dtoToAnalyticsBucket(dto.bucket!),
});

export const dtoToAnalyticsBucketOfBucketValues = <
  UNIT extends AnalyticsUnit,
  BUCKET extends AnalyticsBucket,
  SUB_BUCKET extends AnalyticsBucket
>(
  metricName: AnalyticsMetricNameDto,
  dto: AnalyticsBucketOfBucketedValuesMetricDto
): AnalyticsBucketOfBucketValues<UNIT, BUCKET, SUB_BUCKET> => ({
  ...dtoToAnalyticsValueBase<UNIT>(metricName, dto),
  bucket: dtoToAnalyticsBucket(dto.bucket!),
  subBucket: dtoToAnalyticsBucket(dto.subBucket!),
  values: dto.values.map((value) => dtoToBucketOfBucketedValuesValue(value)),
});

export const dtoToAnalyticsValues = <D extends {}>(name: AnalyticsMetricNameDto, dto: D) => {
  if ("subBucket" in dto)
    return dtoToAnalyticsBucketOfBucketValues(name, dto as unknown as AnalyticsBucketOfBucketedValuesMetricDto);
  if ("bucket" in dto) return dtoToAnalyticsBucketedValue(name, dto as unknown as AnalyticsBucketMetricDto);
  if ("value" in dto) return dtoToAnalyticsSingleValue(name, dto as unknown as AnalyticsSingleValueDto);
};

export const dtoToAnalyticsAppendixType = (dto: string): AnalyticsAppendixType => dto as AnalyticsAppendixType;
export const dtoToAnalyticsAppendixTypeMap: {
  [T in AnalyticsAppendixType]: (dto: AnalyticsAppendixTypeMap[T]["dto"]) => AnalyticsAppendixTypeMap[T]["fe"];
} = {
  people: dtoToThinPerson,
};

export const dtoToAnalyticsResultsApendixEntry = <T extends AnalyticsAppendixType>(
  typeKey: T,
  dto: RichAppendixEntryDto
): AnalyticsResultsApendixEntry<T> => ({
  missingScopes: dto.missingScopes,
  values: mapObject(dto.values, ([key, value]) => [key, dtoToAnalyticsAppendixTypeMap[typeKey](value)]),
});
export const dtoToAnalyticsResultsApendix = (dto: Record<string, RichAppendixEntryDto>): AnalyticsResultsApendix =>
  mapObject(dto, ([key, value]) => {
    const typeKey = dtoToAnalyticsAppendixType(key);
    return [typeKey, dtoToAnalyticsResultsApendixEntry(typeKey, value)];
  });

/**
 * response mutators
 */

export const dtoToAnalyticsMetrics = <METRICS extends AnalyticssMetric[]>(
  dto: AnalyticsResultsDto
): AnalyticsResults<METRICS> => ({
  metrics: mapToObject(dto.metrics, ({ metric, name }) => [
    name,
    dtoToAnalyticsValues(name, metric),
  ]) as unknown as AnalyticsResults<METRICS>["metrics"],
  appendix: dtoToAnalyticsResultsApendix(dto.appendix),
});
